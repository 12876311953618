@use "../../../App.module.scss" as style;

.header {
  text-align: left;
}

.dropdownContainer{
  display: flex;
  @include style.responsiveFilters();
}

