@use "../../../App.module.scss" as style;
.header {
  text-align: left;
}
.boxContainer {
  @include style.responsiveFilters();
  align-items: flex-end;
}

.gradedStockEnabled {
  width: 100%;
}

.upperButtonSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  margin-bottom: 20px;
}

.termsConsitionBox {
  background-color: #d1ecf1;
  padding: 5px 20px;
  border-radius: 5px;
  margin-top: 50px;
}

@media print {
  .upperButtonSection {
    display: none;
  }
  @page {
    size: 400mm 200mm;
  }
}
