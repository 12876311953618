.tableContainer {
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  margin: 32px 0;

  .tableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }
}

.header {
  text-align: left;
}

.headerBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
