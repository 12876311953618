.imageContainer {
  padding: 20px;
  max-width: fit-content;
  max-height: 350px;
  overflow: auto;
}
.slider {
  padding: 20px;
  margin-top: 10px;
}

.infoContainer {
  background-color: #d1ecf1;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
}

.imageStyle {
  overflow-y: scroll;
  width: 40;
  height: 40;
  max-width: fit-content;
  max-height: 300px;
  @media screen and (max-width: 630px) {
    width: 200px;
  }
}
