.header {
  text-align: left;
}

.boxStyle {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.innerBoxStyle {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.buttonStyle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.linkStyle {
  cursor: pointer;
  color: blue;
}

.linkStyle:hover {
  text-decoration: underline;
}

.productBoxStyle {
  background-color: #f9f6f6;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  padding: 16px;
}

.innerProductBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: none;
  color: #5887f5;
}

.innerProductBox:hover {
  text-decoration: underline;
}
