.buttonStyle {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media screen and (max-width: 900px) {
    flex-direction: row !important;
    width: 100%;
  }
}

.buttonContainer {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  @media screen and (max-width: 900px) {
    flex-direction: row !important;
  }
}
