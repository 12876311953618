@use "../../App.module.scss" as style;

@mixin tabCommonDesign(
    $color: #fff,
    $width: 50%,
    $border-radius: 10px,
    $cursor: pointer
) {
    color: $color;
    width: $width;
    border-radius: $border-radius;
    cursor: $cursor;
}

.boxStyle {
  background-color: #f9f6f6;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  text-align: center;
  padding: 16px;
}

.tabs{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .activeTab{
        background-color: #f25529;
        @include tabCommonDesign()
    }
    
    .secondTab{
        @include tabCommonDesign(#f25529)
    }
    @media screen and (max-width: 500px) {
        display: block;
        .activeTab{
            width: 100%;
        }
        
        .secondTab{
            width: 100%;
        }
    }
}

.innerBox{
    padding:20px;
    text-align: left;
}