.tabContainer {
  padding: 16px 0;
  border-top: 1px solid #c8c8c8;
}
.tabsStyle {
  min-height: 40px !important;
  height: 40px !important;
  align-items: center;
  button {
    font-size: 12px;
  }
}
