@use "../../../App.module.scss" as style;

.header {
  text-align: left;
}

.boxStyle {
  margin-bottom: 40px;
  @include style.responsiveFilters();
}
