.warningMsg {
  background-color: #d1ecf1;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
}

.boxDesign {
  margin-top: 25px;
}

.innerBox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
