@mixin responsiveFilters(
  $columnDirection: flex-start,
  $itemsAlignment: center,
  $paddingvalue: 0,
  $gapBetweenItems: 16px
) {
  display: flex;
  gap: $gapBetweenItems;
  flex-wrap: wrap;
  justify-content: $columnDirection;
  align-items: $itemsAlignment;
  padding: $paddingvalue;
}

@mixin tableContainer(
  $border: 1px solid #c8c8c8,
  $borderRadius: 5px,
  $marginValue: 32px 0
) {
  border: $border;
  border-radius: $borderRadius;
  margin: $marginValue;
}

.innerContainer {
  margin: 40px;
  margin-top: 100px;
}

@mixin bgColor {
  background-color: #f9f6f6;
}
