.HeaderBgColor {
  background-color: black !important;
}

.logoStyle {
  cursor: pointer;
  width: 70px;
  // display: flex;
  padding-right: 16px;
  object-fit: contain;
}
.headerNumText {
  font-family: "SST W01 Roman" !important;
}
.headerText {
  text-align: initial;
  font-family: "SST W01 Light" !important;
  font-size: 18px !important; 
  @media screen and (max-width: 790px) {
    display: none;
  }
}
@font-face {
  font-family: "SST W01 Light";
  src: url("../../assets/fonts/SSTW02Light.woff2") format("woff2"),
    url("../../assets/fonts/SSTW02BoldItalic.eot") format("eot"),
    url("../../assets/fonts/SSTW02Light.woff") format("woff"),
    url("../../assets/fonts/SSTW02Light.ttf") format("truetype"),
    url("../../assets/fonts/SSTW02Light.eot?#iefix") format("eot");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SST W01 Roman";
  src: url("../../assets/fonts/SSTW02Roman.woff2") format("woff2"),
    url("../../assets/fonts/SSTW02BoldItalic.eot") format("eot"),
    url("../../assets/fonts/SSTW02Roman.woff") format("woff"),
    url("../../assets/fonts/SSTW02Roman.ttf") format("truetype"),
    url("../../assets/fonts/SSTW02Roman.eot?#iefix") format("eot");
  font-weight: normal;
  font-style: normal;
}

.toolbar {
  display: flex;
  justify-content: space-between;
}

.toolbarContent {
  display: flex;
  align-items: center;
}

.toolbarProfileContent{
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: flex-end;
}

.toolbarIconOptions{
  display: flex;
  align-items: center;
  gap: 5px;
}

.profileMenuItems{
  text-decoration: none;
  color: #000;
}

.headerMenuIconsTray {
  text-align: end;
}
