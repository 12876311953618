.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalSubHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0 16px 0;
}

.selectOrdeselectAll {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.listDesign {
  display: flex;
  flex-direction: column;
  justify-items: center;
  //   gap: 15px;
  margin-bottom: 16px;
}

.listItemDesign {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.buttonSection {
  display: flex;
  gap: 10px;
}

.checkboxStyle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.errMsg {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 0.75rem 1.25rem;
  margin-bottom: 16px;
}
