.header {
    color: #f25529;
    text-align: left;
}

.tableContainer {
    border: 1px solid #c8c8c8;
    border-radius: 5px;
    margin: 32px 0;
  
    .tableHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
    }
    .buttonStyle {
      background-color: #f25529;
    }
  }

  .colorBlue{
    color: blue;
    text-decoration: none;
  }

  .colorBlue:hover{
    text-decoration: underline;
  }