@use "../../../App.module.scss" as style;

.header {
  text-align: left;
}
.boxContainer {
  @include style.responsiveFilters();
}
.textField {
  background-color: white;
}
.datePickerDiv {
  display: flex;
  align-items: center;
  .datePicker {
    background-color: white;
  }
  .infoIcon {
    margin-left: 12px;
  }
}

.tableContainer {
  @include style.tableContainer();

  .tableHeader {
    @include style.responsiveFilters(space-between, center, 16px, 10px);
  }
  .buttonStyle {
    background-color: #f25529;
  }
}
