.modalStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #000;
  box-shadow: 24;
  border-radius: 8px;
  text-align: center;
  min-width: 50%;
  @media screen and (max-width: 630px) {
    width: 90%;
  }
}

.headerStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px 4px 16px;
}
