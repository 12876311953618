.tableContainerHeader {
  border-top: 1px solid #c8c8c8;
  margin: 0px;
  th {
    background-color: #f9f6f6;
    font-weight: bold;
    color: #727272;
  }
  .pdfIcon {
    cursor: pointer;
  }
  td {
    padding: 4px 16px;
  }
}

.tableFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px 16px;
}

.noRecFound {
  padding: 24px !important;
}

.colorBlue {
  color: blue;
  text-decoration: none;
}

.colorBlue:hover {
  text-decoration: underline;
}
