.buttonContainer {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 570px) {
    flex-direction: column-reverse;
  }
}
.tabsStyle {
  min-height: 40px !important;
  height: 40px !important;
  align-items: center;
  border-bottom: 1px solid #c8c8c8;
  button {
    font-size: 12px;
  }
}
.tableContainer {
  margin-top: 24px;
  td {
    text-align: left;
    vertical-align: top;
    border: 1px solid rgba(224, 224, 224, 1);
  }
}
.linksListStyle {
  list-style-type: none;
  width: fit-content;
  padding-left: 0px;
  margin-top: 0px;
  li {
    padding-top: 4px;
  }
}
.links {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: #f25529;
  }
}
.featureUl {
  padding-left: 16px;
  margin-top: 4px;
}
.label {
  font-weight: 600;
}

.listStyle {
  color: #5887f5;
}

.listStyle:hover {
  text-decoration: underline;
  cursor: pointer;
}
.qtyContainer{
  display: flex;
}
.availabilityIcon {
  transform: scale(0.7);
}

.searchCartStyle {
  display: flex;
  justify-content: space-between;
}
