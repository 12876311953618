.menuWidth {
  width: 20%;
}

.detailsDiv {
  padding: 10px 20px 0 20px;
  font-size: 14px;
}

.label {
  font-size: 12px;
}

.header {
  font-size: 16px;
  color: #f25529;
  font-weight: 500;
}

.footerDiv {
  padding: 10px 0 20px 20px;
  font-size: 14px;
}
