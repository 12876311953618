@use "../../../App.module.scss" as style;

.productListBox {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.buttonSection {
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;
  flex-wrap: wrap;
  gap: 20px;
}

.tableContainer {
  @include style.tableContainer();

  .tableHeader {
    @include style.responsiveFilters(flex-end, center, 16px, 10px);
  }
}

.endMsgFormat {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 15px;
}

.linkStyle {
  text-decoration: none;
  color: blue;
}

.linkStyle:hover {
  text-decoration: underline;
}
