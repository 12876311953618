@use "../../../../App.module.scss" as style;

.tableContainer {
  border: 1px solid #c8c8c8;
  th {
    background-color: #f9f6f6;
    font-weight: bold;
    color: #727272;
    // text-transform: uppercase;
  }
  .pdfIcon {
    cursor: pointer;
  }
  .disableDownload {
    pointer-events: none;
  }
  td {
    padding: 6px 16px;
    // border: 1px ;
  }
  tr {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
}

.tableBox {
  @include style.tableContainer(1px solid #c8c8c8, 5px, 0);

  .tableHeader {
    @include style.responsiveFilters(flex-end, center, 16px, 10px);
  }
  .buttonStyle {
    background-color: #f25529;
  }
}

.tableFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  @include style.responsiveFilters(space-between, center, 4px 16px, 0);
  .totalAmount {
    display: flex;
    gap: 5px;
    p {
      font-weight: bold;
    }
  }
}
@media screen and (max-width: 673px) {
  .tableFooter {
    @include style.responsiveFilters(space-between, center, 16px 16px, 0);
  }
}

.pagination div {
  padding-left: 2px;
}

.errorText {
  color: red !important;
}

.noRecFound {
  padding: 24px !important;
}

.thAlign {
  display: flex;
}

.tdIconAlign {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invoiceTableCell {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.availabilityIcon {
  transform: scale(0.7);
  // padding-right: 6px;
}

.messageStyle {
  font-family: "SST W01 Roman";
  font-size: 10px;
}

.availabilityDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modelColumn {
  display: flex;
  align-items: center;
}

.imageStyle {
  padding-right: 10px;
  cursor: pointer;
}

.newBadgeStyle {
  padding: 2px 4px;
  background-color: #22c083;
  color: #fff;
  font-size: 10px;
  border-radius: 4px;
  margin-left: 4px;
}

.qtyButtonContaainer {
  display: flex;
  justify-content: space-between;
}
.qtyText {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}
.errorMessageStyle {
  display: flex;
  text-align: center;
}

.colorBlue {
  cursor: pointer;
}

.colorBlue:hover {
  text-decoration: underline;
}

.tableFooterDesign {
  padding: 4px 16px;
  .totalAmount {
    display: flex;
    gap: 5px;
    p {
      font-weight: bold;
    }
  }
}

.buttonSection {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}