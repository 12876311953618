@use "../../../App.module.scss" as style;

.boxStyle {
  background-color: #f9f6f6;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  text-align: left;
  padding: 16px;
}

.changePasswordBox {
  padding: 24px 0px;
}

.newPassBox {
  display: flex;
  gap: 25px;
  margin-bottom: 24px;
  flex-wrap: wrap;
}
