.infoIcon {
  transform: scale(0.8);
}

.dateBox {
  display: flex;
}

.sendEmailtoAllContacts {
  display: flex;
  align-items: center;
}

.sendEmailContainer {
  margin-top: 20px;
}

.buttonBox {
  display: flex;
  justify-content: flex-end;
}
