@import "../../App.module.scss";

.infoContainer {
  @include bgColor;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 8px 16px;
}

.infoText {
  margin-top: -2px;
  padding-left: 8px;
}

.bgContainer {
  margin: 24px 0;
}

.accCard {
  @include bgColor;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 16px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  padding: 16px;
  cursor: pointer;
  transition: 0.2s;
}

.accCard:hover {
  background-color: #DCDCDC;
  transform: scale(1.1);
}

.accCard:active {
  background-color: #DCDCDC;
  transform: scale(1);
}

.cardHeading{
  font-weight: bold !important;
  color: rgba(0, 0, 0, 0.7);
}
