.videoContainer {
  padding: 20px;
  max-width: 600px;
  max-height: 350px;
}

.videoBox{
  max-width:  550px;
  max-height: 300px;
  @media screen and (max-width: 630px) {
    width: 300px;
  }
}