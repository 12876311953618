.tableContainer {
  border-top: 1px solid #c8c8c8;
  th {
    background-color: #f9f6f6;
    font-weight: bold;
    color: #727272;
  }
  .pdfIcon {
    cursor: pointer;
  }
  .disableDownload {
    pointer-events: none;
  }
  td {
    padding: 4px 16px;
    border-bottom: none;
  }
  tr{
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
}

.tableFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px 16px;
}

.errorText {
  color: red !important;
}

.noRecFound {
  padding: 24px !important;
}

.amountWidth {
  width: 17%;
}

.invoiceTableCell {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}