.borderBoxStyle {
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  padding: 16px;
}

.imageBoxStyle {
  display: flex;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
}
