.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.listDesign {
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin-bottom: 16px;
}

.errMsg {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 0.45rem 1.25rem;
  margin-bottom: 10px;
  border-radius: 5px;
}

.info {
  background-color: #d1ecf1;
  border-color: #d1ecf1;
  padding: 0.45rem 1.25rem;
  margin-bottom: 10px;
  border-radius: 5px;
}

// .uploadInnerSection {
  // display: flex;
  // align-items: flex-end;
  // gap: 8px;
// }

.filenameArea {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 15px 0;
}

.uploadFileButtonStyle {
  color: #fff;
  background-color: #f25529;
  padding: 2px 10px;
  width:max-content;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.uploadArea {
  border: 1px dotted #000;
  padding: 30px 150px;
}

.dropzoneStyle {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}
