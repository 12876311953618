.header {
  text-align: left;
}

.boxStyle {
  border: 1px solid rgba(0, 0, 0, 0.125);
  width: 70%;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.innerBoxStyle {
  display: flex;
  gap: 15px;
}

.buttonStyle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.imageWidth {
    width: 100%;
}
.imageBlock {
    width: 86px;
}
