@import "../../App.module.scss";

.loginContainer {
  @include bgColor;
  min-height: 100vh;
  // width: 100vw;
}
.boxStyle {
  padding-top: 130px;
  display: flex;
  justify-content: center;
}
.svg_icons {
  transform: scale(2.5);
}

.innerBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  text-align: left;
}

.loginCard {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 16px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: white;
  width: 75%; // Earlier 30%
  padding: 32px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media screen and (max-width: 912px) {
    width: 70%;
  }
}
