.mainContainer {
  padding: 20px;
  display: flex;
  text-align: left;
}
.optionsContainer {
  width: 30%;
  padding: 0 20px 20px 0;
}

.detailsContainer {
  width: 70%;
  padding: 0 20px;
}

.optionHeading {
  font-weight: 600;
}
.checkboxStyle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.buttonContainer {
  display: flex;
  justify-content: left;
}

.radioLabel{
  font-size: 12px !important;
}

.priceContainer{
  margin-bottom: 16px;
}

.hideComponent{
 display: none;
}