.mainContainer {
  border: 1px solid #c8c8c8;
  padding: 16px;
}

.imageStyle {
  margin-bottom: 8px;
}

.listStyle {
  padding: 12px 16px;
}

.buttonStyle{
  display: flex;
  justify-content: flex-end;
}