@use "../../../App.module.scss" as style;

.boxStyle {
  background-color: #f9f6f6;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  text-align: center;
  padding: 16px;
}

.cardStyle {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 32px;
  @include style.responsiveFilters(space-evenly, center, 0, 16px);
}

.svg_icons {
  transform: scale(1.6);
}
.divStyle {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  text-align: center;
  @include style.responsiveFilters(space-between, center, 0, 32px);
}
.dualBox {
  background-color: #f9f6f6;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  padding: 16px;
  width: 100%;
}

.headerButtonStyle {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}

.informationBoxStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
