
  .card {
    height: 200px;
  }

  .multiProds {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
  }

  
.subtitle1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  text-align: left;
}
  
.subtitle2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  text-align: left;
}
  