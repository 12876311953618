@use "../../../App.module.scss" as style;

.header {
    text-align: left;
}

.openBoxContainer {
    @include style.responsiveFilters();
}

.referenceFilterStyle{
  display: flex;
  align-items: flex-end;
  margin-bottom: 4px;
  gap: 10px;
}

.tableContainer {
    @include style.tableContainer();
  
    .openTableHeader {
      @include style.responsiveFilters(space-between, center, 16px, 10px);
    }
    .openSingleTableHeader {
      @include style.responsiveFilters(flex-end, center, 16px, 10px);
    }
  }