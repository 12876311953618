@use "../../../App.module.scss" as style;

.header {
  text-align: left;
}

.tableContainer {
  @include style.tableContainer();

  .tableHeader {
    @include style.responsiveFilters(space-between, center, 16px, 10px);
  }
}

.boxStyle {
  margin-bottom: 10px;
}

.quoteTitle {
  margin: 15px 0;
}

.projectDescription {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 10px 0;
}

.buttonSection {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}

.dealerDetailBox {
  border: 1px solid #000;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttonBoxStyle {
  background-color: #efefef;
  padding: 5px;
  border-radius: 2px;
}

.primeSupportBox {
  color: #856404;
  background-color: #fff3cd;
  border-radius: 1px;
  padding: 0.75rem 1.25rem;
}
