@import "../../../App.module.scss";

// .boxStyle {
//   margin: 20px 40px;
//   margin-top: 80px;
// }
.boxStyle {
  background-color: #f9f6f6;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  text-align: left;
  padding: 16px;
}
.boxContainer {
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  padding: 16px;
}

.salDropdown {
  display: flex;
}

.infoIcon {
  margin-left: 8px;
  transform: scale(0.8);
}

.accValidIcon {
  transform: scale(0.8);
  cursor: auto !important;
}

.infoContainer {
  @include bgColor;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 8px 16px;
}

.radioText {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem !important;
  line-height: 1.43 !important;
  letter-spacing: 0.01071em !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.checkboxStyle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.errStyle {
  color: #d32f2f !important;
}