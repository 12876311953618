@use "../../../../App.module.scss" as style;

.tableContainer {
  border-top: 1px solid #c8c8c8;
  th {
    background-color: #f9f6f6;
    font-weight: bold;
    color: #727272;
  }
  .pdfIcon {
    cursor: pointer;
  }
  .disableDownload {
    pointer-events: none;
  }
  td {
    padding: 4px 16px;
    border-bottom: none;
  }
  tr {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
}

.tableFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  @include style.responsiveFilters(space-between, center, 4px 16px, 0);
  .totalAmount {
    display: flex;
    p {
      font-weight: bold;
    }
  }
}
@media screen and (max-width: 673px) {
  .tableFooter {
    @include style.responsiveFilters(space-between, center, 16px 16px, 0);
  }
}

.pagination div {
  padding-left: 2px;
}

.errorText {
  color: red !important;
}

.noRecFound {
  padding: 24px !important;
}

.thAlign {
  display: flex;
}

.tdIconAlign {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invoiceTableCell {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}
