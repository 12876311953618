@use "../../../App.module.scss" as style;

.header {
  text-align: left;
}
.boxContainer {
  @include style.responsiveFilters();
}
.tableContainer {
  @include style.tableContainer();

  .tableHeader {
    @include style.responsiveFilters(flex-end, center, 16px, 10px);
  }
}
