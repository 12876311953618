.topBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 25px;
}

.containerMargin {
  margin-top: 25px;
  display: flex;
  align-items: center;
}

.checkboxesDesign {
  display: flex;
  align-items: center;
}

.infoIcon {
  transform: scale(0.8);
}

.mainContainer {
  width: 100%;
}
