.header {
  text-align: left;
}

.boxStyle {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.innerBoxStyle {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.buttonStyle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.titleLink {
  cursor: pointer;
}

.titleSection {
  display: flex;
  justify-content: space-between;
  word-break: break-word;
}

@mixin categoryIcon {
  display: block;
  float: right;
  width: 40px;
  height: 30px;
  background-image: url("../../../assets/images/bcgCategoryIcons.gif");
  background-color: #ffffff;
  background-repeat: no-repeat;
  text-indent: -9999px;
}
.Filter_1_HomeEntertainmentNetwork {
  background-position: 0px 0px;
  @include categoryIcon();
}
.Filter_10_Reader {
  background-position: 0px -270px;
  @include categoryIcon();
}
.Filter_2_3D {
  background-position: 0px -30px;
  @include categoryIcon();
}
.Filter_11_HomeAudio {
  background-position: 0px -300px;
  @include categoryIcon();
}
.Filter_3_SonyInternetTV {
  background-position: 0px -60px;
  @include categoryIcon();
}
.Filter_12_PortableAudio {
  background-position: 0px -330px;
  @include categoryIcon();
}
.Filter_4_TV {
  background-position: 0px -90px;
  @include categoryIcon();
}
.Filter_13_Playstation {
  background-position: 0px -360px;
  @include categoryIcon();
}
.Filter_5_HomeCinema {
  background-position: 0px -120px;
  @include categoryIcon();
}
.Filter_14_MemoryDiscsBatteries {
  background-position: 0px -390px;
  @include categoryIcon();
}
.Filter_6_Cameras {
  background-position: 0px -150px;
  @include categoryIcon();
}
.Filter_15_CarEntertainment {
  background-position: 0px -420px;
  @include categoryIcon();
}
.Filter_7_Camcorders {
  background-position: 0px -180px;
  @include categoryIcon();
}
.Filter_16_Accessories {
  background-position: 0px -450px;
  @include categoryIcon();
}
.Filter_8_LaptopsAndComputing {
  background-position: 0px -210px;
  @include categoryIcon();
}
.Filter_17_Events {
  background-position: 0px -480px;
  @include categoryIcon();
}
.Filter_9_Tablet {
  background-position: 0px -240px;
  @include categoryIcon();
}
.Filter_18_Other {
  background-position: 0px -510px;
  @include categoryIcon();
}
