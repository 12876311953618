.cardMedia {
  margin-left: auto;
  margin-right: auto;
}

.card {
  height: 260px;
}

.cardContent {
  text-align: center;
}