.tabsStyle {
  min-height: 40px !important;
  height: 40px !important;
  align-items: center;
}

.infoContainer {
  background-color: #d1ecf1;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  width: fit-content;
}

.disclaimerStyle {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: "#6c757d";
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  width: fit-content;
}

.errorStyle {
  border-radius: 5px;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 4px 8px;
  color: #721c24;
  text-align: initial;
  word-break: break-word;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: fit-content;
}

.optionStyle {
  border: 1px solid grey;
}
