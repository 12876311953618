.errorStyle {
  border-radius: 5px;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 8px 16px;
  color: #721c24;
  text-align: initial;
  word-break: break-word;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.infoStyle {
  border-radius: 5px;
  background-color: #fff3cd;
  border-color: #ffeeba;
  padding: 16px 16px;
  color: #856404;
  text-align: initial;
  margin-bottom: 16px;
}

ul {
  margin-bottom: 0px;
}