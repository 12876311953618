.label {
  font-weight: 600;
}

.availabilityIcon {
  transform: scale(0.7);
}
.messageStyle {
  font-family: "SST W01 Roman";
  font-size: 10px;
}

.availabilityDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listStyle {
  color: #5887f5;
}

.listStyle:hover {
  text-decoration: underline;
  cursor: pointer;
}

.qtyButtonContaainer {
  display: flex;
  justify-content: left;
}

.searchCartStyle {
  display: flex;
  justify-content: space-between;
}
