@use "../../../App.module.scss" as style;

.header {
    text-align: left;
}

.paidBoxContainer {
    @include style.responsiveFilters();
}

.yourReferenceStyle{
  display:flex;
  align-items: flex-end;
  margin-bottom: 4px;
  gap: 10px;
}

.tableContainer {
    @include style.tableContainer();
  
    .tableHeader {
      @include style.responsiveFilters(space-between, center, 16px, 10px);
    }
  }