.box_idleTimer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.textAlign{ 
    text-align: left; 
}

.button_wrapper{
    display: flex; 
    justify-content: flex-end; 
    flex-wrap: wrap; 
}