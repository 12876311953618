.cardStyle {
  width: 90%;
  border-radius: 10px !important;
  margin-bottom: 16px;
  border: 1px solid lightgray;
}
@media screen and (max-width: 900px) {
  .cardStyle {
    width: 100%;
  }
}
.cardContent {
  padding: 12px !important;
}
.commentContainer {
  display: flex;
}
.commentHeader {
  display: flex;
  justify-content: space-between;
}
.contentDiv {
  width: 100%;
  padding-left: 16px;
}
.timerIcon {
  transform: scale(0.7);
  margin-top: -3px;
}
.dateContainer {
  display: flex;
  align-items: flex-start;
}
.mainContainer {
  display: flex;
}
.newComments {
  background-color: #f9f6f6;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  padding: 20px;
  height: fit-content;
  margin-bottom: 16px;
}
