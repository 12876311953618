@import "../../App.module.scss";

.countryContainer {
  @include bgColor;
  min-height: 100vh;
  width: 100%;
}
.header{
    padding-left: 5px;
}
.countryBox{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.boxStyle {
    padding-top: 120px;
    padding-left: 90px;
    padding-bottom: 20px;
    text-align: left;
}

.countryCard {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 16px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: white;
  width: 18%;
  padding: 16px 10px;
  margin: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  @media screen and (max-width: 912px) {
    width: 70%;
  }
}
