@use "../../../App.module.scss" as style;

.headerBox{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.header {
    text-align: left;
}

.tableContainer {
  @include style.tableContainer();
  
    .tableHeader {
      @include style.responsiveFilters(space-between, center, 16px, 10px);
    }
  }