.header {
  text-align: left;
}
.changeButton {
  margin-top: 12px;
  margin-left: 15px;
  // @media screen and (min-width: 440px) {
  //   margin-left: 15px;
  //   margin-top: 0px;
  // }
}

.boxContainer {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  // @media screen and (max-width: 440px) {
  //   flex-direction: column;
  // }
  // justify-content: space-between;
}
.errorMessage {
  border-radius: 5px;
  background-color: blanchedalmond;
  padding: 4px 8px;
  color: tomato;
  text-align: initial;
  word-break: break-word;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  width: fit-content;
}
