.imageBox {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.mainImage {
  width: 50%;
}
.newBadgeStyle {
  padding: 2px 4px;
  background-color: #22c083;
  color: #fff;
  font-size: 10px;
  border-radius: 4px;
  margin-left: 8px;
  vertical-align: middle;
}

.linksListStyle {
  list-style-type: none;
  width: fit-content;
  li {
    border-bottom: 1px solid #c8c8c8;
    padding: 8px 0;
  }
}

.links {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: #f25529;
  }
}

.buttonStyles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
