.header {
  text-align: left;
}

.gridCustomDesign {
  display: flex;
  align-items: center;
}

.gridMultiSelectCustomDesign {
  display: flex;
  align-items: flex-end;
  gap: 5px;
}

.orderLine {
  background-color: #fff3cd;
  padding: 5px;
}

.chipBox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.activeChip {
  background-color: #f25529;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #f25529;
  color: #fff;
  cursor: pointer;
}

.chip {
  border: 1px solid #f25529;
  padding: 5px;
  border-radius: 10px;
  color: #f25529;
  cursor: pointer;
}

.buttonBox {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.dateSection {
  display: flex;
  align-items: flex-end;
}

.errorBackground {
  color: #721c24;
  background-color: #f8d7da;
  padding: 0.50rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 1px;
}