.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.textLink:hover {
  text-decoration: underline;
  cursor: pointer;
}
