@import "../../App.module.scss";

.resetPassContainer {
  @include bgColor;
  min-height: 100vh;
  width: 100%;
}

.boxStyle {
  padding-top: 120px;
  display: flex;
  justify-content: center;
}
.svg_icons {
  transform: scale(2.5);
}

.resetPassCard {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 16px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: white;
  width: 35%;
  padding: 16px 32px;
  text-align: center;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 912px) {
    width: 70%;
  }
}

.resetExpiredPassCard {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 16px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: white;
  width: 45%;
  padding: 16px 32px;
  text-align: center;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 912px) {
    width: 70%;
  }
}

.resetHeader {
  margin: 32px;
}

.resetPassSubHeading {
  text-align: left;
  margin-left: 16px;
  margin-bottom: 12px;
}

.buttonStyle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.errorMessage {
  border-radius: 4px;
  background-color: blanchedalmond;
  padding: 4px 8px;
  color: tomato;
  text-align: initial;
  margin: 10px 16px !important;
  word-break: break-word;
  display: flex;
  align-items: center;
}
