@use "../../App.module.scss" as style;

.header {
  text-align: left;
}

.tableContainer {
  @include style.tableContainer();

  .tableHeader {
    @include style.responsiveFilters(space-between, center, 16px, 10px);
  }
}

.noDataMsg {
  margin: 10px 0;
  padding: 5px 10px;
  background-color: #f8d7da;
  border-radius: 5px;
  color: #721c24;
}
