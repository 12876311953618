@use "../../../App.module.scss" as style;
.header {
  text-align: left;
}
.boxContainer {
  @include style.responsiveFilters();
}

.bottomButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}

.endMsg {
  background-color: #d6d8d9;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.tableContainer {
  @include style.tableContainer();
}

.topBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 25px;
}

.showReport {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.buttonsSection {
  display: flex;
  justify-content: flex-end;
}

.errorMsg {
  background-color: #f8d7da;
  padding: 0.75rem 1.25rem;
  border-radius: 1px;
}
