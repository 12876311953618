.formContainer {
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  margin-top: 32px;
  padding: 32px;
}
.currencyDropdown {
  // justify-content: center;
  display: flex;
}
