.footerStyle {
  min-height: 64px;
  padding: 0px 50px;
  background-color: black;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  position: relative;
  width: -webkit-fill-available;
  bottom: 0;
}

.footerMsg {
  cursor: pointer;
}
