.header {
  text-align: left;
}

.subCategory {
  display: flex;
}

.subCategoryItems {
  border: 1px solid gray;
}

.imageContainer {
  border: 1px solid grey;
  border-radius: 5px;
  width: fit-content;
  cursor: pointer;
}
.imageStyle {
  padding: 10px 20px 0px 20px;
}

.noImageStyle {
  padding: 30px 30px 0px 30px;
}

.checkboxStyle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.boxContainer {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 24px 28px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 12px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
