@use "../../../../App.module.scss" as style;
.header {
  text-align: left;
}
.boxContainer {
  @include style.responsiveFilters();
  align-items: flex-end;
}

.tableContainer {
  @include style.tableContainer(1px solid #c8c8c8, 5px, 5px 0 0 0);
}

.endMsg {
  background-color: #d6d8d9;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.orderBox {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 10px;
  align-items: center;
}

.textWidth {
  width: 250px;
}

.printDesign {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media print {
  @page {
    size: 550mm 250mm;
  }
}

.errorMsg {
  color: #721c24;
  background-color: #f8d7da;
  padding: 5px;
  border-radius: 2px;
}

.sendEmailBox {
  display: flex;
}
