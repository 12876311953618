@use "../../App.module.scss" as style;

.boxStyle {
  background-color: #f9f6f6;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  padding: 16px;
}

.buttonBoxStyle {
  margin-top: 30px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.mainContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 25px;
}
