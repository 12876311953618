@use "../../../App.module.scss" as style;

.warningStyle {
  border-radius: 5px;
  background-color: #fff3cd;
  border-color: #ffeeba;
  padding: 4px 8px;
  color: #856404;
  text-align: initial;
  word-break: break-word;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: fit-content;
}

.infoStyle {
  border-radius: 5px;
  background-color: #d1ecf1;
  border-color: #bee5eb;
  padding: 4px 8px;
  color: #0c5460;
  text-align: initial;
  word-break: break-word;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: fit-content;
}

.tableContainer {
  border-top: 1px solid #c8c8c8;
  th {
    background-color: #f9f6f6;
    font-weight: bold;
    color: #727272;
    padding: 10px 16px;
  }
  .pdfIcon {
    cursor: pointer;
  }
  td {
    padding: 10px 16px;
    border-bottom: none;
  }
  tr {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
}

.mainTableContainer {
  @include style.tableContainer(1px solid #c8c8c8, 0, 0);

  .tableHeader {
    @include style.responsiveFilters(space-between, center, 12px, 10px);
  }
}
