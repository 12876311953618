.tableContainer {
    border-top: 1px solid #c8c8c8;
    th {
      background-color: #f9f6f6;
      font-weight: bold;
      color: #727272;
    }
    .pdfIcon {
      cursor: pointer;
    }
    td {
      padding: 10px 16px;
      border-bottom: none;
    }
    tr{
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
  }