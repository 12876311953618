.header {
  text-align: left;
}

.subCategory {
  display: flex;
}

.subCategoryItems {
  border: 1px solid gray;
}

.imageContainer{
  border: 1px solid grey;
  border-radius: 5px;
  width: fit-content;
  cursor: pointer;
}
.imageStyle {
  padding: 10px 20px 0px 20px;
}

.noImageStyle{
  padding: 30px 30px 0px 30px;
}

.checkboxStyle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.buttonContainer{
  display: flex;
  justify-content: flex-end;
}