.linkStyle{
    color: #666;
    text-decoration: none;
    font-weight: 600;
    font-size: small;
}

.linkStyle:hover{
    text-decoration: underline;
}

.currentPageText{
    font-size: small;
    color: #666;
    margin-top: 1px;
}