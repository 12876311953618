@use "../../../App.module.scss" as style;

.headerBox{
  display: flex;
}

.header {
  text-align: left;
}

.tableContainer {
  @include style.tableContainer();
  
  .tableHeader {
    @include style.responsiveFilters(space-between, center, 16px, 10px);
  }
}