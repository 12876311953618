.mainContainer {
  padding: 20px;
}
.infoContainer {
  background-color: #d1ecf1;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
}
.downloadButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
