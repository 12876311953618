.warningMsg {
  background-color: #d1ecf1;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
}

.boxDesign {
  margin-top: 25px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoIcon {
  transform: scale(0.8);
}

.deliveryOption {
  display: flex;
  align-items: center;
}

.formLabelStyle {
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  font-weight: 700;
  color: #000;
}

.processMsgStyle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
