@import "../../../App.module.scss";

.loginContainer {
  @include bgColor;
  min-height: 100vh;
  // width: 100vw;
}
.boxStyle {
  padding-top: 130px;
  display: flex;
  justify-content: center;
}
.svg_icons {
  transform: scale(2.5);
}

.loginCard {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 16px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: white;
  width: 75%;// Earlier 30%
  padding: 16px 32px;
  text-align: center;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 912px) {
    width: 70%;
  }
}

.loginIcon {
  margin: 32px;
}

.buttonStyle {
  text-align: end;
  // uncomment below to have remember me checkbox
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.rememberBlock {
  margin:7px 11px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.forgotPassBlock{
  margin:7px 16px 7px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.errorMessage {
  border-radius: 4px;
  background-color: blanchedalmond;
  padding: 4px 8px;
  color: tomato;
  text-align: initial;
  margin: 10px 16px !important;
  word-break: break-word;
  display: flex;
  align-items: center;
}

.registration {
  text-align: center;
  margin-top: 8px;
  cursor: pointer;
}

.hyperlinkText:hover {
  text-decoration: underline;
}

.cmsContentLinks {
  color: #5887f5;
  margin: 5px 20px;
  text-align: left;
}

.cmsContentLinks:hover {
  text-decoration: underline;
  cursor: pointer;
}