@use "../../../App.module.scss" as style;

.headerBox{
    @include style.responsiveFilters(flex-start, center, 0, 0)
}

.header {
    text-align: left;
}

.tableContainer {
    @include style.tableContainer();
  
    .tableHeader {
      @include style.responsiveFilters(space-between, center, 16px, 10px);
    }
  }